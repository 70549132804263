import React from "react";
import stock from "../../../images/stock11.png";
import ai from "../../../images/ai13.jpg"

const NISMSeriesVIIIDerivativesMarket = () => {
  return (
    <div className="container">
      <div className="container mt-5 mb-5">
        <p className="allheading">Derivative Market Module - NISM CERTIFICATION</p>
        <div className="row">
          <div className="col-md-6">
          <img width={'100%'} src={ai} alt="" />
          </div>
          <div className="col-md-6">
        <p className="para" style={{ color: "gray" }}>
          NISM Derivative Market (NSE) Dealer Module Certification & Exam
          Preparation by TSEINSTITUTE Leading Stock Market Institute For
          Financial Market Courses in Delhi NCR. Derivative Market dealer
          module, a one-month program for aspirants wish to master Derivative
          Market operations. This module is starting level of stock market
          courses for job. The program offers powerful insight into various
          tools and techniques need to understand ca Derivative Market size,
          structure, and functions. Accelerate your career as a Derivative
          Market dealer to get a job in National Stock Exchange (NSE). NISM
          Derivative Market module is one of the most important programs of the
          National Stock Exchange (NSE). It is a must-do module to get a job in
          Stock Broking Company. Aspirants planning to start their own stock
          broking business can also enroll for this short-term course. Besides,
          who wants to add value to resume when applying for jobs in the
          financial sector/ IT/ BPO.
        </p>

          </div>
          <div className="col-md-6">
          <p className="para mt-5">
          <b>Unique Features of Derivative Market Module</b>
        </p>
        <p className="para" style={{ color: "gray" }}>
          The NSE / NISM Derivative Market course prepares students to attain a
          position in the Derivative Market as an analyst or strategist.
          Derivative Market is one of the hottest professions. The demand for
          Derivative Market analyst will rise to 22% by 2020. TSE Institute
          Derivative Market Module gives a foundational knowledge of Derivative
          Market instruments, fundamental valuation, major exchange, legal
          framework, and more.
        </p>
        <p className="para">
          <b>What Student Will Get?</b>
        </p>
        <p className="para" style={{ color: "gray" }}>
          Upon completion of the Capital Market Module, students will receive a
          certificate from TSEand from NCFM. The certificate will testify your
          skills as an expert in the capital market. You will also receive the
          following:
        </p>
        <ol>
          <li> NSE Derivative Market Certification</li>
          <li> NISM Derivative Market Certification</li>
          <li> TSECERTIFICATION – Certification of Derivative Market Course</li>
        </ol>
        <p className="para" style={{ color: "gray" }}>
          # NCFM Certificates are subjected to appearance for the exam at the
          NSE Center. You will get 100% preparation at the branch.
        </p>
          </div>
          <div className="col-md-6 mt-5">
          <img width={'100%'} src={stock} alt="" />

          </div>
        </div>
        
        <p className="heading">DETAILED CURRICULUM</p>

        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Module 1: Derivatives Market Introduction and Fundamentals{" "}
              </p>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>What is Derivatives Market?</li>
                  <li>What Are the Types of Derivatives?</li>
                  <li>Derivatives and Equity Market</li>
                  <li>Evolution of Derivatives Market</li>
                  <li>Indian Derivatives Market</li>
                  <li>Significance of Derivatives</li>
                  <li>Risk in Derivatives Market</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Module 2: Quantitative Fundamentals
              </p>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>What is Beta?</li>
                  <li>What is R-Square?</li>
                  <li>How to do continuous compounding?</li>
                  <li>What is a normal distribution?</li>
                  <li>What is share prices lognormal distribution?</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Module 3: Derivatives Market Volatility
              </p>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>Historical Volatility (?)</li>
                  <li>ARCH Model</li>
                  <li>Exponentially Weighted Moving Average (EWMA)</li>
                  <li>GARCH Model</li>
                  <li>Implied Volatility</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                Module 4: Introduction to Forwards
              </p>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>Contracts</li>
                  <li>Selection Criteria</li>
                  <li>Stock Selection Criteria</li>
                  <li>Criteria for Continued Eligibility of Stock</li>
                  <li>Criteria for Re-inclusion of Excluded Stocks</li>
                  <li>Index Selection Criteria</li>
                  <li>Price Steps and Price Bands for Contracts</li>
                  <li>Quantity Freeze for Futures Contracts</li>
                  <li>Novation</li>
                  <li>Margins Daily Mark-to-Market Settlement</li>
                  <li>Final Settlement</li>
                  <li>Cost of Carry</li>
                  <li>Determining Stock Futures Price (without Dividend)</li>
                  <li>Determining Stock Futures Price (with Dividend)</li>
                  <li>Determining Index Futures Price (without Dividend)</li>
                  <li>Determining Index Futures Price (with Dividend)</li>
                  <li>Cash & Carry Arbitrage</li>
                  <li>Reverse Cash & Carry Arbitrage</li>
                  <li>Convergence of Spot & Futures</li>
                  <li>Contango & Backwardation</li>
                  <li>Cost of Carry – Commodities</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFive">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                Module 5: Understanding Index
              </p>
            </h2>
            <div
              id="flush-collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>Introduction to Index</li>
                  <li>Significance and economic purpose of Index</li>
                  <li>Types of Indices</li>
                  <li>Attributes of an Index and concept of impact cost</li>
                  <li>Index management</li>
                  <li>Major Indices in India</li>
                  <li>Applications of Index</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingSix">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix"
                aria-expanded="false"
                aria-controls="flush-collapseSix"
              >
                Module 6: Introduction to Options and Trading Stratgies
              </p>
            </h2>
            <div
              id="flush-collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingSix"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>Basics of options</li>
                  <li>Payoff Charts for Options</li>
                  <li>Basics of options pricing and option Greeks</li>
                  <li>Fundamentals of options pricing</li>
                  <li>
                    Overview of Binomial and Black-Scholes option pricing models
                  </li>
                  <li>Basics of Option Greeks</li>
                  <li>Uses of Options</li>
                  <li>Option spreads and their payoff charts</li>
                  <li>Straddle: market view and payoff charts</li>
                  <li>Strangle: market view and payoff charts</li>
                  <li>Covered Call: market view and payoff charts</li>
                  <li>Protective Put: market view and payoff charts</li>
                  <li>Collar: market view and payoff charts</li>
                  <li>Butterfly spread: market view and payoff charts</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingSeven">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSeven"
                aria-expanded="false"
                aria-controls="flush-collapseSeven"
              >
                Module 7: Introduction to Clearing and Settlement system
              </p>
            </h2>
            <div
              id="flush-collapseSeven"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingSeven"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>Clearing Members, their role and eligibility norms</li>
                  <li>Clearing Mechanism and computation of open positions</li>
                  <li>
                    Settlement Mechanism for stock and index futures and options
                  </li>
                  <li>Understanding margining and mark to market under SPAN</li>
                  <li>Risk Management features and position limits</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEight">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEight"
                aria-expanded="false"
                aria-controls="flush-collapseEight"
              >
                Module 8: Regulatory Frame Work
              </p>
            </h2>
            <div
              id="flush-collapseEight"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingEight"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>Objective of Regulation</li>
                  <li>Regulation of Exchanges</li>
                  <li>Eligibility for derivative trading</li>
                  <li>Regulatory control on contracts</li>
                  <li>Risk Management at Clearing</li>
                  <li>Different aspects of risks</li>
                  <li>Market Manipulation</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingNine">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseNine"
                aria-expanded="false"
                aria-controls="flush-collapseNine"
              >
                Module 9: Accounting of Derivatives
              </p>
            </h2>
            <div
              id="flush-collapseNine"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingNine"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>Accounting of Futures and Options contracts</li>
                  <li> Taxation of Derivative transaction in securities</li>
                </ol>
              </div>
            </div>
          </div>
          <p className="para">
            <b>Who Can Take Derivative Market Module?</b>
          </p>
          <p className="para" style={{ color: "gray" }}>
            Aspirants who complete the Derivative Market course will develop a
            skill that helps you land your dream job in the primary and
            secondary market. Jobs that are ideal for Derivative Market
            professionals include:
          </p>
          <ol>
            <li>
               Derivative Market beginners can take the course to build a
              lucrative career in financial services.
            </li>
            <li>
               Teachers, investors, and anybody interest to work in Indian stock
              market operations can join the Derivative Market module to enhance
              learning.
            </li>
            <li>
               Stock brokers, sub-brokers, and stock analyst those looking to
              open new career opportunities can take the course.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default NISMSeriesVIIIDerivativesMarket;

import React from "react";
import stock from "../../../images/stock7.png";
import ai from "../../../images/ai8.jpg";


const EquityMarketTrader = () => {
  return (
    <div className="container mt-5 mb-5">
          <p className="allheading">Equity Market Trader (EMT)</p>
      <div className="row">
        <div className="col-md-6">
          <img width={'100%'} height={'100%'} src={stock} alt="Equity Market Trador" />
        </div>
        <div className="col-md-6">
          <p className="para">
            Equity Market Trader Course is a two-month program formulated for
            those who wish to enter the Stock Market and become Stock Market
            Dealers, system operators. This course covers four markets of Indian
            stock industry viz capital market/derivative market
          </p>
          <p className="para">
            It is a basic program in Job Oriented series with 100% Placement
            Records. Certified Stock Market Trader Course is a 100% JOB ORIENTED
            PROGRAM.
          </p>
        </div>
        <div className="col-md-6">

        <div class="accordion accordion-flush mt-5" id="accordionFlushExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              Unique Feature of the Program
            </p>
          </h2>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>
                  This is a beginner program for all job seekers in the
                  financial/broking industry.
                </li>
                <li>
                  You can upgrade further to a diploma and advanced diploma.
                </li>
                <li>A complete blend of theory + practical.</li>
                <li>
                  Faculty with 15 years’ experience in teaching & trading.
                </li>
                <li>Practice in live markets.</li>
                <li>Filling out account opening forms/IPOs, mutual funds.</li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingTwo">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              Managing records how to check Client portfolio, management of
              clients
            </p>
          </h2>
          <div
            id="flush-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>A great add-on course along with Graduation, MBA</li>
                <li>Industry recognized program</li>
                <li>
                  Based on theory as well as practical for complete knowledge
                </li>
                <li>You enter the industry completely trained and fit</li>
                <li>
                  Workshops and other programs and meet with industry experts
                </li>
                <li>Become 100% confident to trade and earn</li>
                <li>
                  5 tier exposure: Faculty, Practical Faculty, Older investor
                  and Trader, Research Team, group discussion, Reliance
                  Securities
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              What Will You Get After Equity Market Trader?
            </p>
          </h2>
          <div
            id="flush-collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>NCFM Capital Market Module certification</li>
                <li>NISM Equity Derivative Market Module Certification</li>
                <li>TSE Certification- Certified Stock Market Trader</li>
              </ol>
              <p className="heading">Job Opportunities</p>
              <p className="heading">
                After Certified in Stock Market Trader Course
              </p>
              <p className="para">
                This is a beginner program for all job seekers in the
                financial/broking industry who can pursue a career as stock
                market dealers, system operators, and more.
              </p>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingFour">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="false"
              aria-controls="flush-collapseFour"
            >
              Certifications
            </p>
          </h2>
          <div
            id="flush-collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingFour"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>1 Certificates of NISM Modules</li>
                <li>1 Certificates of NCFM Modules</li>
                <li>
                  TSE CERTIFICATION – Certified Stock Market Trader Course
                </li>
              </ol>
              <p className="heading">
                *NCFM and NISM Certificates are subjected to appearance for the
                exam at the NSE Centre. You will get 100% preparation at the
                branch.
              </p>
            </div>
          </div>
        </div>

        <p className="heading mt-5 mb-2">DETAILED CURRICULUM</p>

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingFive">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFive"
              aria-expanded="false"
              aria-controls="flush-collapseFive"
            >
              Module 1: Capital Market & its Operation (NCFM certification)
            </p>
          </h2>
          <div
            id="flush-collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingFive"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>
                  Basic knowledge of capital market (Primary Market & secondary
                  Market)
                </li>
                <li>Major Market Participants</li>
                <li>Major exchanges and indices</li>
                <li>Legal Framework and regulations</li>
                <li>Trading and Trading Membership</li>
                <li>Clearing and Settlement Process</li>
                <li>
                  Fundamental Valuation concepts Glimpse of various trading
                  software (ODIN, NEAT, NOW) Practical training of market
                  operations
                </li>
                <li>
                  Practical Classes daily from 10.00 am – 03.30 pm (live trading
                  and methodology)
                </li>
                <li>
                  Online Mock test (500 Question & answer test series) of NCFM
                  Capital Market
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingSix">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSix"
              aria-expanded="false"
              aria-controls="flush-collapseSix"
            >
              Module 2: Derivatives Market & its Operations (NISM Certification)
            </p>
          </h2>
          <div
            id="flush-collapseSix"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingSix"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>
                  Introduction to Derivatives, Type of derivative contracts
                </li>
                <li>Understanding of future, forward, option and SWAP</li>
                <li>
                  Future contracts, Mechanism & pricing of Forward contracts
                </li>
                <li>Understanding of Options and it is (call & put)</li>
                <li>
                  Trading, Clearing, and Settlement, Risk Management in
                  Derivatives
                </li>
                <li>Regulatory Frame Work</li>
                <li>Accounting of Derivatives</li>
                <li>
                  Practical Classes of 10 hours (live trading and methodology
                  (Futures & Options)
                </li>
                <li>
                  Online Mock test (1000 Question & answer test series) of NISM
                  Derivative market module
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingSeven">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSeven"
              aria-expanded="false"
              aria-controls="flush-collapseSeven"
            >
              Who Should Do It?
            </p>
          </h2>
          <div
            id="flush-collapseSeven"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingSeven"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>MBA & BBA/CA/CS/CPT Students</li>
                <li>Anyone who wants the job</li>
                <li>10+2, BA, B.Com, B.Sc. Pursuing Students</li>
                <li>Those who want to pursue a career in financial services</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
        </div>
        <div className="col-md-6 mt-5">
        <img width={'100%'} src={ai} alt="Equity Market Trador" />

        </div>
      </div>


    </div>
  );
};

export default EquityMarketTrader;

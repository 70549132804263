import React from "react";
import ai  from '../../../images/stock8.png'
import stock from '../../../images/ai10.jpg'
const DiplomaFinancial = () => {
  return (
    <div className="container mt-5 mb-5">
      <p className="allheading">Diploma in Financial Market Management</p>
      <div className="row">
        <div className="col-md-6">
        <img width={'100%'} src={stock} alt="Equity Market Trador" />

        </div>
        <div className="col-md-6">

      <p className="para">
        About <b>“Diploma in Financial Market Management” </b> a Six-Month
        program specially designed for all students and professionals who wish
        to specialize in the stock market. This is one of its programs for all
        those who wish to do specialization in stock market trading (Equity,
        Equity Derivative, Currency & Commodity Market, and Technical Analysis
        Fundamental Analysis. This course covers Options Basic Strategies and
        Advanced Greeks. A Diploma in Financial and Stock Market is a 6 months
        job-oriented course. Join a rigorous program as we walk you through
        study on legitimate theoretical financial market. In 7 NSE modules,
        learn subject areas of financial market courses including essential
        aspects of the securities market. Besides, the Financial Market Course
        covers important topics, practices, and trends in the subject area.
      </p>
      <p className="para">
        Financial market course is a valuable degree requires entering lucrative
        career in securities market. Moreover, gain specialized practical
        training in operation and live trading from mentors. Live market
        training in key principles, policies, framework, and applications. Each
        skill drives competent knowledge and ultimately powerful expertise.
        Become a master trader overnight!
      </p>
        </div>
        <div className="col-md-6">
        <div class="accordion accordion-flush mt-5" id="accordionFlushExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              What Will You Get After Equity Market Trader?
            </p>
          </h2>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <p className="para">
                Diploma in stock market Professional is the most sought program
                after 10+2. Accomplish the course in 7 months duration, divided
                into 7 NSE modules you will surely enjoy. The course focuses on
                study and operation of:
              </p>
              <ol>
                <li>NCFM Capital Market Module certification</li>
                <li>NISM Equity Derivative Market Module</li>
                <li>NCFM Commodity Market Module certification</li>
                <li>NISM Currency Derivative Market Module</li>
                <li>NCFM Technical Analysis Module certification</li>
                <li>NCFM Option Trading Strategies Module certification</li>
                <li>NCFM Fundamental Analysis Module certification</li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingTwo">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              Eligibility
            </p>
          </h2>
          <div
            id="flush-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <p className="para">
                Participants seeking answers to the question ‘what is the
                eligibility to the financial market course?’ Can find simplified
                answer in this section. The basic eligibility requires to pursue
                a diploma in stock market Professional courses is.
              </p>
              <ol>
                <li>
                  Minimum eligibility 10/ 10+12 from recognized board or
                  institutions.
                </li>
                <li>
                  Candidates having a background in Any Stream (Arts, Comm. &
                  Science)
                </li>
                <li>
                  Graduates who want to expand career horizons in the financial
                  market
                </li>
                <li>
                  Working professionals seeking exposure to securities market
                </li>
                <li>
                  Investors and traders looking for formal education or
                  employment
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              Admission Criteria
            </p>
          </h2>
          <div
            id="flush-collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>
                  Submit self-attested 10th, 12th graduation, including other
                  professional degree certificates and mark sheets at the time
                  of admission.
                </li>
                <li>
                  Also, verification is done based on original documents.
                  Failing to which admission application is rejected.
                </li>
                <li>
                  95% attendance is a must during the program in each module
                  opted by the student.
                </li>
                <li>
                  Faculty and centre head remarks/recommendation are considered
                  during the final assessment.
                </li>
                <li>
                  Your certification depends on your Attendance, class
                  assessment, projects, internal exams, NSE exams, Practical
                  classes, Projects, and Viva.
                </li>
                <li>
                  The decision of the Centre in Charge and centre Head will be
                  final.
                </li>
                <li>
                  *Placement guarantee refers to (After getting NCFM and NISM
                  Certification of all 10 modules covered in adv. diploma
                  course).
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingFour">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="false"
              aria-controls="flush-collapseFour"
            >
              Why Choose (Diploma in Financial Market Management) Course From
              TSE?
            </p>
          </h2>
          <div
            id="flush-collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingFour"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>
                  Students from 150+ countries infused from rich cultural
                  diversity
                </li>
                <li>
                  Team of technical analysts and research analysts with 25+
                  years of experience faculty
                </li>
                <li>
                  Access to a world-class trading lab to practice live market
                </li>
                <li>
                  Opportunity to network with industry leaders during workshop
                  training like SEBI, Reliance Capital
                </li>
                <li>
                  Consistent online and offline NSE mock tests for constructive
                  feedback
                </li>
                <li>
                  Diploma in Stock Market Professional course fee is very
                  affordable compared to other full-time courses
                </li>
                <li>Hands-on learning with more than 25+ trading software</li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingFive">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFive"
              aria-expanded="false"
              aria-controls="flush-collapseFive"
            >
              Job Opportunities and Prospects
            </p>
          </h2>
          <div
            id="flush-collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingFive"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <p className="para">
                DSMP is highly job-oriented course providing opportunities in
                the stock exchange. A career in financial and stock market
                offers immense growth as an increase in skill demand in the
                upcoming sector. Job opportunities after financial market course
                beings right after completing the program. After completing the
                course, you can apply in organizations like
              </p>
              <ol>
                <li>Financial institutions as financial advisor</li>
                <li>
                  Investment bank as a relationship manager or stock analyst
                </li>
                <li>Stock broking as broker or sub broker</li>
                <li>
                  Hedge funds as a fund manager, equity trader, research analyst
                </li>
                <li>
                  Mutual funds as a wealth manager, customer acquisition, and
                  portfolio manager
                </li>
                <li>
                  KPOs as research analyst, equity research, or share market
                  trader
                </li>
                <li>
                  Depositories as investment management and operations managers
                </li>
                <li>Facility company as asset management</li>
              </ol>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingSix">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSix"
              aria-expanded="false"
              aria-controls="flush-collapseSix"
            >
              NSE - Certifications
            </p>
          </h2>
          <div
            id="flush-collapseSix"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingSix"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>2 CERTIFICATES OF NISM MODULES</li>
                <li>5 CERTIFICATES OF NCFM MODULES</li>
                <li>TSE - DIPLOMA IN FINANCIAL MARKET MANAGEMENT</li>
              </ol>
              <p className="para">
                *NCFM and NISM Certificates are subjected to appearance for the
                exam at the NSE Centre. You get 100% preparation at the branch.
              </p>
            </div>
          </div>
        </div>
        <p className="heading mt-5 mb-3">DETAILED CURRICULUM</p>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingSeven">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSeven"
              aria-expanded="false"
              aria-controls="flush-collapseSeven"
            >
              Module 1: capital market & its operation (NCFM certification)
            </p>
          </h2>
          <div
            id="flush-collapseSeven"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingSeven"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>
                  Basic knowledge of capital market (Primary Market & secondary
                  Market)
                </li>
                <li>Major Market Participants</li>
                <li>Major exchanges and indices</li>
                <li>Legal Framework and regulations</li>
                <li>Trading and Trading Membership</li>
                <li>Clearing and Settlement Process</li>
                <li>
                  Fundamental Valuation concepts Glimpse of various trading
                  software (ODIN, NEAT, NOW) Practical training of market
                  operations
                </li>
                <li>
                  Practical Classes daily from 10.00 am – 03.30 pm (live trading
                  and methodology)
                </li>
                <li>
                  Online Mock test (500 Question & answer test series) of NCFM
                  Capital Market
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingEight">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseEight"
              aria-expanded="false"
              aria-controls="flush-collapseEight"
            >
              Module 2: Derivatives Market & its Operations (NISM Certification)
            </p>
          </h2>
          <div
            id="flush-collapseEight"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingEight"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>
                  Introduction to Derivatives, Type of derivative contracts
                </li>
                <li>Understanding of future, forward, option and SWAP</li>
                <li>
                  Future contracts, Mechanism & pricing of Forward contracts
                </li>
                <li>Understanding of Options and it is (call & put)</li>
                <li>
                  Trading, Clearing, and Settlement, Risk Management in
                  Derivatives
                </li>
                <li>Regulatory Frame Work</li>
                <li>Accounting of Derivatives</li>
                <li>
                  Practical Classes of 10 hours (live trading and methodology
                  (Futures & Options)
                </li>
                <li>
                  Online Mock test (1000 Question & answer test series) of NISM
                  Derivative market module
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingNine">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseNine"
              aria-expanded="false"
              aria-controls="flush-collapseNine"
            >
              Module 3: Commodity Market (NCFM Certification)
            </p>
          </h2>
          <div
            id="flush-collapseNine"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingNine"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>Understanding Commodity Market</li>
                <li>Live Trading in Commodities – MCX and NCDEX</li>
                <li>International commodity Vs Domestic Commodity</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingTen">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTen"
              aria-expanded="false"
              aria-controls="flush-collapseTen"
            >
              Module 4: Currency Market (NISM Certification)
            </p>
          </h2>
          <div
            id="flush-collapseTen"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingTen"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>Currency Derivative and its History</li>
                <li>Forward, Future and Options of currency derivative</li>
                <li>
                  Trading, clearing, settlement & Risk Management of currency
                  futures
                </li>
                <li>Different Strategy Use for Currency Derivative Trading</li>
                <li>
                  Live Trading practices (2 Hours) in currency derivative
                  segment
                </li>
                <li>
                  Online Mock test (800 Question & answer test series) of NISM
                  series1
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingEleven">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseEleven"
              aria-expanded="false"
              aria-controls="flush-collapseEleven"
            >
              Module 5: Technical Analysis and its Application (NCFM
              Certification) Introduction about Technical Analysis
            </p>
          </h2>
          <div
            id="flush-collapseEleven"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingEleven"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <p className="para">
                What is technical analysis? The basis of technical analysis
                Difference between technical vs fundamental analysis
              </p>
              <p className="heading">Type of Charts</p>
              <p className="para">The various types of price charts</p>
              <ol>
                <li>Line chart</li>
                <li>Bar chart</li>
                <li>Candlestick chart</li>
                <li>Kagi chart</li>
                <li>Point & Figure chart</li>
                <li>Renko chart</li>
                <li>Three Line Break chart</li>
              </ol>
              <p className="heading">Trend lines</p>
              <ol>
                <li>What is the purpose of drawing trend lines?</li>
                <li>How to plot trend lines</li>
              </ol>
              <p className="heading">How to plot trend lines</p>
              <p className="heading">
                <b>One candlestick pattern</b>
              </p>
              <ol>
                <li>Doji</li>
                <li>Hammer / Hanging Man</li>
                <li>Inverted Hammer / Shooting Star</li>
                <li>Spinning Top</li>
                <li>Marubozu</li>
              </ol>
              <p className="para">
                <b>Double candlestick pattern</b>
              </p>
              <ol>
                <li>Bullish / Bearish Engulf</li>
                <li>Bullish / Bearish Harami</li>
                <li>Piercing Pattern / Dark Cloud Cover</li>
                <li>Tweezer Top & Bottom</li>
              </ol>

              <p className="para">
                <b>Triple Candlestick Pattern</b>
              </p>

              <ol>
                <li>Morning Star / Evening Star</li>
                <li>Three White Soldiers / Three Black Crows</li>
                <li>Abandoned Body (Bullish & Bearish)</li>
                <li>Tasuki Gap (Bullish & Bearish)</li>
              </ol>
              <p className="para">
                <b>Five Candlestick Pattern</b>
              </p>

              <ol>
                <li>Rising Three Methods & Falling Three Methods</li>
                <li>Support & Resistance</li>
                <li>Change of Support to Resistance and Vice Versa</li>
              </ol>

              <p className="para">
                <b>Chart Patterns and Their Study</b>
              </p>
              <b>Four Stages</b>
              <ol>
                <li>Accumulation</li>
                <li>Markup</li>
                <li>Distribution</li>
                <li>Panic Liquidation</li>
              </ol>
              <p className="para">
                <b>Chart Patterns</b>
              </p>
              <ol>
                <li>Head & Shoulder</li>
                <li>Inverted Head & Shoulder</li>
                <li>Double Top/Bottom</li>
                <li>Flag & Pennant</li>
                <li>Symmetrical, Ascending, Descending Triangles</li>
                <li>Wedge Patterns</li>
                <li>Rounding Top/Bottom</li>
                <li>Cup & Handle</li>
                <li>Rectangles Bullish / Bearish</li>
                <li>Triple Top/Bottom</li>
              </ol>
              <b>Gaps & Gaps Analysis</b>
              <p className="heading">
                <b className="para">Types of Gaps</b>
              </p>
              <ol>
                <li>Common Gap</li>
                <li>Breakaway Gap</li>
                <li>Runaway Gap</li>
                <li>Exhaustion Gap</li>
                <li>Island Cluster</li>
              </ol>
              <b>Oscillators & Indicators</b>
              <p className="para">
                What does a technical indicator offer? Why use indicators?
              </p>
              <p className="para">
                <b>Types of Indicators</b>
              </p>
              <ol>
                <li>Leading Indicator</li>
                <li>Lagging Indicator</li>
              </ol>
              <ol>
                <li>
                  <b>Moving Averages</b>
                  <ol>
                    <li>Simple moving average</li>
                    <li>Exponential moving average</li>
                    <li>How to trade on moving averages?</li>
                  </ol>
                </li>
                <li>
                  <b>MACD</b>
                  <ol>
                    <li>What is the MACD and how is it calculated?</li>
                    <li>How to trade on MACD?</li>
                  </ol>
                </li>
                <li>
                  <b>RSI</b>
                  <ol>
                    <li>What is momentum?</li>
                    <li>Calculation of the RSI</li>
                    <li>Divergence</li>
                    <li>How to trade on RSI?</li>
                  </ol>
                </li>
                <li>
                  <b>On Balance Volume</b>
                  <ol>
                    <li>Overview</li>
                    <li>Calculation of On Balance Volume</li>
                    <li>How to trade on On Balance Volume</li>
                  </ol>
                </li>
                <li>
                  <b>Stochastic</b>
                  <ol>
                    <li>Overview</li>
                    <li>Construction</li>
                    <li>How to trade on Stochastic</li>
                  </ol>
                </li>
                <li>
                  <b>William %R</b>
                  <ol>
                    <li>Overview</li>
                    <li>Signals</li>
                    <li>William %R</li>
                  </ol>
                </li>
                <li>
                  <b>Bollinger Bands</b>
                  <ol>
                    <li>Few rules for beginners</li>
                    <li>How to trade on Bollinger Bands</li>
                    <li>How to use multiple indicators</li>
                  </ol>
                </li>
                <li>
                  <b>Money Flow Index</b>
                  <ol>
                    <li>Overview</li>
                    <li>How to trade on Money Flow Index</li>
                  </ol>
                </li>
                <li>
                  <b>Trading Strategy</b>
                </li>
                <li>
                  <b>The Dow Theory</b>
                  <ol>
                    <li>Background</li>
                    <li>Principal rule of the Dow Theory</li>
                  </ol>
                </li>
                <li>
                  <b>Fibonacci Sequence</b>
                  <ol>
                    <li>How to trade on Fibonacci retracement</li>
                    <li>How to trade on Fibonacci extension</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div>
          <p className="para">
            <b>Inclusive With Trading Psychology and how to manage the risk</b>
          </p>
          <p className="para">
            {" "}
            GTS - Growth Trading Strategies- On Intraday & Positional Trade
            Include 7 Technical Analysis Trading Strategies.
          </p>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingTwelve">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwelve"
              aria-expanded="false"
              aria-controls="flush-collapseTwelve"
            >
              Module 6: Option Strategy (NCFM Certification) INTRODUCTION TO
              OPTIONS
            </p>
          </h2>
          <div
            id="flush-collapseTwelve"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingTwelve"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <li>Option Terminology</li>
                <li>
                  Options Payoffs
                  <ol>
                    <li>Payoff profile of buyer of asset: Long asset</li>
                    <li>Payoff profile for the seller of asset: Short asset</li>
                    <li>
                      Payoff profile for buyers of call options: Long call
                    </li>
                    <li>
                      Payoff profile for the writer (seller) of call options:
                      Short call
                    </li>
                    <li>Payoff profile for buyers of put options: Long put</li>
                  </ol>
                </li>
                <li>
                  Strategies
                  <ol>
                    <li>Long Call</li>
                    <li>Short Call</li>
                    <li>Synthetic Long Call</li>
                    <li>Long Put</li>
                    <li>Short Put</li>
                    <li>Covered Call</li>
                    <li>Long Combo</li>
                    <li>Protective Call</li>
                    <li>Covered Put</li>
                    <li>Long Straddle</li>
                    <li>Short Straddle</li>
                    <li>Long Strangle</li>
                    <li>Short Strangle</li>
                    <li>Collar</li>
                    <li>Bull Call Spread Strategy</li>
                    <li>Bull Put Spread Strategy</li>
                    <li>Bear Call Spread Strategy</li>
                    <li>Bear Put Spread Strategy</li>
                    <li>Long Call Butterfly</li>
                    <li>Short Call Butterfly</li>
                    <li>Long Call Condor</li>
                    <li>Short Call Condor</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThirteen">
            <p
              class="accordion-button collapsed accordionheading"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThirteen"
              aria-expanded="false"
              aria-controls="flush-collapseThirteen"
            >
              Module 7: Fundamental Analysis (NCFM Certification)
            </p>
          </h2>
          <div
            id="flush-collapseThirteen"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThirteen"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol>
                <p className="heading">Introduction of Fundamental Analysis</p>
                <ol>
                  <li>What is Fundamental & Technical Analysis?</li>
                  <li>Difference between technical & fundamental analysis</li>
                  <li>Features & benefits of Fundamental analysis</li>
                </ol>
                <p className="heading">
                  Top-Down Approach in Fundamental Analysis
                </p>
                <ol>
                  <li>Economic Analysis</li>
                  <li>Industry Analysis</li>
                  <li>Company analysis</li>
                </ol>
                <p className="heading">
                  Economic Analysis Global & Domestic Research (Events)
                </p>
                <ol>
                  <ol>
                    <li>Political events (Election Resolts)</li>
                    <li>Central Bank Meet (Central Bank events)</li>
                    <li>Government Budget</li>
                    <li>Monsoon</li>
                    <li>OPEC Meeting</li>
                    <li>War or Terrorist Attack</li>
                  </ol>
                  <p className="heading">Other Events</p>
                  <ol>
                    <li>Rating agencies</li>
                    <li>India VIX</li>
                    <li>Scandal</li>
                    <li>Insider Activity</li>
                    <li>Country Debt</li>
                  </ol>
                    <p className="heading">
                      CURRENCY ANALYSIS CORRELATION OF EVENTS ECONOMIC DATA
                      ANALYSIS
                    </p>
                    <ol>
                      <li>Inventory</li>
                      <li>Retail Sales</li>
                      <li>Consumer Confidence</li>
                      <li>CPI – Consumer Price Index</li>
                      <li>PPI – Producer Price Index</li>
                      <li>Core Durable Goods Order</li>
                      <li>New Homes Sales</li>
                      <li>Building Permits</li>
                      <li>ADP Non-Farm employment</li>
                      <li>Non-Farm Employment</li>
                      <li>Unemployment Claims</li>
                      <li>PMI</li>
                      <li>Industrial Production</li>
                      <li>GDP Gross Domestic Production</li>
                      <li>Trade Balance</li>
                      <li>Crude Oil Inventory</li>
                    </ol>
                </ol>
                <p className="heading"> Industry Analysis
                SECTOR ANALYSIS (SECTOR SELECTION)</p>
                <ol>
                    <ol>
                      <li>Oil & Gas Sector</li>
                      <li>Aviation Sector</li>
                      <li>Paint Sector</li>
                      <li>T. Sector</li>
                      <li>Metal Sector</li>
                      <li>Pharma& FMCG Sector</li>
                      <li>Jem’s & Jewellery Sector</li>
                      <li>Banking Sector</li>
                      <li>Automobile Sector</li>
                      <li>Real Estate Sector</li>
                      <li>Cement Sector</li>
                      <li>Telecom Sector</li>
                      <li>Power Sector</li>
                    </ol>
                </ol>
                <p className="heading">
                  Script Selection Share or Commodity Selection
                </p>
                <p className="heading">Company Analysis (Valuation)</p>
                <ol>
                  <li>Cash Flow</li>
                  <li>EPS</li>
                  <li>P/E</li>
                  <li>Book Value</li>
                  <li>Price to Book Value</li>
                  <li>EBITDA</li>
                  <li>Profit Ratios</li>
                  <li>Dividend</li>
                  <li>Market Cap</li>
                  <li>DEBT</li>
                  <li>Management</li>
                  <li>Promoters Holding</li>
                  <li>Volume</li>
                  <li>Open Interest</li>
                  <li>Beta</li>
                  <li>Moving Average</li>
                </ol>
              </ol>
            </div>
          </div>
        </div>
        <p className="para"><b># Inclusive With Mastering Fundamental Analysis Strategies (MFA) -</b></p>
        <p className="para" style={{color:'gray'}}>
        This Strategy is based on Market & Data Analysis.
        </p>
        <p className="para">
<b>Who Should Do Diploma in & Stock Market Professional Course?</b>
        </p>
        <ol>
    <li>MBA & BBA/CA/CS/CPT Students</li>
    <li>Anyone who wants the job</li>
    <li>10+2, BA. B.com. B.Sc. Pursuing Students</li>
    <li>Those who want to pursue the career in financial services</li>
</ol>
      </div>
        </div>
        <div className="col-md-6 mt-5">
            <img src={ai} width={'100%'} alt="" />
        </div>
      </div>

     
    </div>
  );
};

export default DiplomaFinancial;

import React from 'react'


const Franchise = () => {
  return (
    <>
      <div>
        <div className="franchiseImage">
          <div className="overlay">
          <p className='franchise'>
             BUSINESS ASSOCIATE PARTNER  <span style={{ color: "#4e7733", fontWeight: "700" }}>Franchise Opportunity with TSE</span></p>          </div>
        </div>
        <div className="container mt-4">
      <div className="cards">
        <div className="card-body">
          <h2 className="allheading">Overview</h2>
          <p className="para card-text">
            TSE is rapidly expanding and aims to establish a strong presence across India to reach students of various ages and professions. To achieve this goal, TSE is adopting the franchise model.
          </p>

          <h3 className="heading">Course Offering</h3>
          <p className="para card-text">
            Students from diverse fields, including MBA graduates in Finance, Marketing, and HR, can specialize in the stock market, an emerging sector in the financial market. The course combines theoretical and practical learning, covering:
          </p>
          <ul>
            <li className='para'>Capital, derivatives, options, commodity, and currency market operations</li>
            <li className='para'>Broking industry, technical analysis</li>
            <li className='para'>Financial institutions and banks</li>
          </ul>
          <p>The course lasts three months and is part of the BFSI segment. It includes three modules with preparation and certification for NSE and SEBI, along with hands-on training on live stock market trading software.</p>

          <h3 className="heading">Franchisee Requirements</h3>
          <ul>
            <li className='para'>A dedicated team aiming to become market leaders</li>
            <li className='para'>Experienced and notable faculty</li>
            <li className='para'>Advanced NSE capabilities that can be integrated with TSE</li>
            <li className='para'>A Growth Trading Strategies (GTS) plan emphasizing flexibility and transparency</li>
            <li className='para'>A commercial space of 1000-1500 sq. ft. in a prime location</li>
            <li className='para'>NCFM and NISM certifications in Derivative, Capital, Technical, Currency, and Commodity Markets</li>
          </ul>

          <h3 className="heading">Application Process</h3>
          <p>Submit the following details to the corporate office at <a href="mailto:tradingsmartedge@gmail.com">tradingsmartedge@gmail.com</a>:</p>
          <ul>
            <li className='para'>Company name and address</li>
            <li className='para'>Age and academic qualifications</li>
            <li className='para'>Number of NCFM and NISM certifications</li>
            <li className='para'>Contact details (cell phone and email)</li>
            <li className='para'>Location</li>
            <li className='para'>Clear photographs of the interior and exterior of the premises</li>
          </ul>

          <h3 className="heading">Financials</h3>
          <ul>
            <li className='para'>A senior official will visit your location for inspection (costs for outstation visits to be borne by the franchisee). Alternatively, you can visit the Delhi or Noida campus.</li>
            <li className='para'>Upon approval, a Franchise Fee of INR 1,11,000 is required for the Franchisee kit to commence operations.</li>
            <li className='para'>Training staff and seminars on the opening day can be supported by TSE, but expenses are the responsibility of the franchise owner.</li>
          </ul>

          <p className="para card-text text-center mt-4">
            TSE is committed to spreading stock market education through its franchise network, treating franchisees as partners and supporting their growth to enhance the Institute's reputation across India.
          </p>

          <p className="heading">
            For further details : 
          </p>
          <p className="heading">
          email : <a className='heading' href="mailto:tradingsmartedge@gmail.com">tradingsmartedge@gmail.com</a>

          </p>
          <p className="heading">
          contact : 9650725689, 9599245542
          </p>
        </div>
      </div>
    </div>
      </div>
    </>
  )
}

export default Franchise
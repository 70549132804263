import React from "react";
import "../Navbar/resources.css";
const Resources = () => {
  return (
    <>
      <div className="resources_bg">
        <div className="overlay">
          <div className="container">
            <h1 style={{ color: "red" }}>
              What are the career options in stock trading?
            </h1>
            <p style={{ color: "white" }}>
              A stockbroker is a well-known profession within the stock market,
              where experts use their market knowledge to aid clients and
              facilitate transactions. Aside from brokerage, there are numerous
              other lucrative career paths available in stock trading. These
              include roles in data analytics, consultancy, research, and
              portfolio management services. The qualifications, skills, and
              on-the-job training required for these positions vary
              significantly.
            </p>
            <p style={{ color: "white" }}>
              For example, research professionals gather and synthesize
              information to draw conclusions and insights. Analysts interpret
              stock market data to provide valuable perspectives on market
              performance and associated risks. Administrative professionals
              oversee hedge funds and mutual funds, implementing financial
              strategies to enhance client portfolio performance. Consultants
              engage regularly with clients and peers to ensure they are
              well-informed about industry developments and opportunities.
            </p>
            <p style={{ color: "white" }}>
              Overall, these roles offer diverse opportunities for professionals
              in the stock market, each requiring specialized skills and
              expertise to navigate the complexities of financial markets
              effectively.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <p className="contactHeading">Top 10 careers in stock market</p>
      </div>
    </>
  );
};

export default Resources;

import React from "react";
import stock from "../../../images/stock9.png";
import ai from "../../../images/ai11.jpg";

const AdvanceDiplomaFinancial = () => {
  return (
    <>
      <div className="container mt-5 mb-5">
        <p className="allheading">
          Advance Diploma In Financial Market Management
        </p>
        <div className="row">
          <div className="col-md-6">
            <img src={ai} width={"100%"} alt="stock" />
          </div>
          <div className="col-md-6">
            <p className="para">
              About “Advance Diploma in Financial Market Management” Advance
              Diploma in stock market professional, a 10 Monthss program
              specially designed for all students and professionals who wish to
              specialize in the stock market, Share market course. It is an
              Advance course in the stock market. This 100% skill oriented and
              100% Job oriented program in Share market and is one of the
              programs for all those who wish to do specialization in Banking
              courses, Stock Market and Share Market courses. Even those
              students who have done MBA Finance, Marketing and even HR or any
              other field can do Specialization in share market which is the
              most upcoming sector. As per NSE, there are going to be 1 crore
              plus jobs in this field in next decade.
            </p>
          </div>
          <div className="col-md-6 mt-5">
          <p className="para">
          ADSMP (Advance Diploma in stock market professional) is a short-term
          course in the stock market with 100% Job guarantee in broking houses,
          financial institutions, and banking industry. One can get stable,
          paying, and white-collar jobs in this field. This is highly reputed
          sector and personnel’s working in Stock Market, share market and
          Banking are considered highly skilful and knowledgeable. The module is
          based on Theory as well as practical.
        </p>
        <p className="para">
          This course is divided into 12 modules, in which All Modules including
          preparation and certification of NSE and SEBI certificates +
          theoretical and practical on live software’s used in stock market
          trading courses. Apart from this 11th module is of internship or on
          the job training.
        </p>
          </div>
          <div className="col-md-6 mt-5 mb-5">
          <img src={stock} width={"100%"} alt="stock" />

          </div>
        </div>
        

        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Unique Feature of Advance Diploma In Financial Market Management
              </p>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p className="para">
                  100% Job oriented course along with graduation
                </p>
                <ol>
                  <li>
                    A complete and comprehensive program for all those who wish
                    to specialize in the stock market
                  </li>
                  <li>A great add-on course along with Graduation, MBA</li>
                  <li>Industry recognized program</li>
                  <li>
                    Based on theory as well as practical for complete knowledge
                  </li>
                  <li>You enter the industry completely trained and fit</li>
                  <li>
                    Faculty with 15 years of experience in Teaching and trading
                  </li>
                  <li>Practice on Live Markets</li>
                  <li>
                    Workshops and other programs and meet with industry experts
                  </li>
                  <li>Become 100% confident to trade and earn</li>
                  <li>
                    5 tier exposure: Faculty, Practical Faculty, Older investor
                    and Trader, Research Team, group discussions
                  </li>
                </ol>
                <p className="para">
                  <b>CERTIFICATIONS</b>
                </p>
                <ol>
                  <li>NCFM Capital Market Module certification</li>
                  <li>
                    NISM Equity Derivative Market Series VIII Module
                    Certification
                  </li>
                  <li>NISM Currency Derivative Market Module certification</li>
                  <li>NCFM Commodity Market certification</li>
                  <li>NCFM Option Strategy Certification</li>
                  <li>NCFM Technical Analysis Certification</li>
                  <li>NCFM Fundamental Analysis</li>
                  <li>NISM Research Analyst SEBI XV Series</li>
                  <li>Mutual Funds NISM VA Certificate</li>
                  <li>
                    TSE Institute Diploma – Advance Diploma in Financial Market
                    Management
                  </li>
                </ol>
                <p className="para" style={{ color: "gray" }}>
                  *NCFM and NISM Certificates are subjected to appearance for
                  the exam at the NSE Centre. You get 100% preparation at the
                  branch.
                </p>
                <p className="para">
                  <b>
                    Job Opportunities after Advance Diploma in Stock Market
                    Professional
                  </b>{" "}
                </p>
                <p className="para">
                  After completing Advance Diploma in Financial Market students
                  can apply for multiple career opportunities in Investment
                  banking or financial services. Students can pursue a career in
                  Indian or international broking house, asset management, hedge
                  funds, PE funds, KPOs, banks, credit rating agency for diverse
                  profiles and in varied departments.
                </p>
              </div>
            </div>
          </div>

          <p className="heading mb-1 mt-5">DETAIL CURRICULUM</p>

          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Module 1: Capital Market & its Operation (NCFM Certification)
              </p>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>
                    Basic knowledge of capital market (Primary Market &
                    secondary Market)
                  </li>
                  <li>Major Market Participants</li>
                  <li>Major exchanges and indices</li>
                  <li>Legal Framework and regulations</li>
                  <li>Trading and Trading Membership</li>
                  <li>Clearing and Settlement Process</li>
                  <li>Fundamental Valuation concepts</li>
                  <li>Glimpse of various trading software (ODIN, NEAT, NOW)</li>
                  <li>Practical training of market operations</li>
                  <li>
                    Practical Classes daily from 9.30 am-03.30 pm (live trading
                    and methodology)
                  </li>
                  <li>
                    Online Mock test (500 Question & answer test series) of NCFM
                    Capital Market Module
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Module 2: Derivatives Market & its Operations (NISM
                Certification)
              </p>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>
                    Introduction to Derivatives, Type of derivative contracts
                  </li>
                  <li>Understanding of future, forward, option and SWAP</li>
                  <li>
                    Future contracts, Mechanism & pricing of Forward contracts
                  </li>
                  <li>Understanding of Options and it’s (call & put)</li>
                  <li>
                    Trading, Clearing, and Settlement, Risk Management in
                    Derivatives
                  </li>
                  <li>Regulatory Framework</li>
                  <li>Accounting of Derivatives</li>
                  <li>
                    Practical Classes daily from 9.30 am-03.30 pm (live trading
                    and methodology) (Futures & Options)
                  </li>
                  <li>
                    Online Mock test (1000 Question & answer test series) of
                    NISM Derivative Market Module
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                Module 3: Commodity Market (NCFM Certification)
              </p>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>Understanding Commodity Market</li>
                  <li>Live Trading in Commodities – MCX and NCDEX</li>
                  <li>International Commodity Vs Domestic Commodity</li>
                  <li>
                    Practical Classes daily from 9.30 am-6 pm (live trading and
                    methodology)
                  </li>
                  <li>
                    Online Mock test (1000 Question & answer test series) of
                    NCFM Commodity Market Module
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFive">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                Module 4: Currency Market (NISM Certification)
              </p>
            </h2>
            <div
              id="flush-collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>Currency Derivative and its History</li>
                  <li>Forward, Future and Options of currency derivative</li>
                  <li>
                    Trading, clearing, settlement & Risk Management of currency
                    futures
                  </li>
                  <li>
                    Different Strategy Use for Currency Derivative Trading
                  </li>
                  <li>Live Trading practices in currency derivative segment</li>
                  <li>
                    Online Mock test (800 Question & answer test series) of NISM
                    Currency Market Module
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingSix">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix"
                aria-expanded="false"
                aria-controls="flush-collapseSix"
              >
                Module 5: Technical Analysis and its Application (NCFM
                Certification) Introduction about Technical Analysis
              </p>
            </h2>
            <div
              id="flush-collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingSix"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p className="para" style={{ color: "gray" }}>
                  What is technical analysis? The basis of technical analysis
                  Difference between technical vs fundamental analysis
                </p>
                <p className="para">
                  Type of Charts The various types of price charts
                </p>
                <ol>
                  <li>Line chart</li>
                  <li>Bar chart</li>
                  <li>Candlestick chart</li>
                  <li>Kagi chart</li>
                  <li>Point & Figure chart</li>
                </ol>
                <p>Trend lines</p>
                <ol>
                  <li>What is the purpose of drawing trend lines?</li>
                  <li>How to plot trend lines</li>
                </ol>
                <p className="para" style={{ color: "red" }}>
                  Candlestick study
                </p>
                <p>One candlestick pattern</p>
                <ol>
                  <li>Doji</li>
                  <li>Hammer / Hanging Man</li>
                  <li>Inverted Hammer / Shooting Star</li>
                  <li>Spinning Top</li>
                  <li>Marubozu</li>
                </ol>
                <p className="para">
                  <b>Double candlestick pattern</b>
                </p>
                <ol>
                  <li>Bullish / Bearish Engulf</li>
                  <li>Bullish / Bearish Harami</li>
                  <li>Piercing Pattern / Dark Cloud Cover</li>
                  <li>Tweezer Top & Bottom</li>
                </ol>
                <p className="para">
                  <b>Triple Candlestick Pattern</b>
                </p>
                <ol>
                  <li>Morning Star / Evening Star</li>
                  <li>Three White Soldiers / Three Black Crows</li>
                  <li>Abandoned Body (Bullish & Bearish)</li>
                  <li>Tasuki Gap (Bullish & Bearish)</li>
                </ol>
                <p className="para">
                  <b>Five candlestick pattern</b>
                </p>
                <ol>
                  <li>MRising three methods & falling three methods</li>
                </ol>
                <p className="para">
                  <b>Support & Resistance</b>
                </p>
                <p className="para">
                  What is Support? What is Resistance Change of support to
                  resistance and vice versa
                </p>
                <p className="para">Charts patterns and their study</p>
                <p className="para">
                  <b>Four Stages</b>
                </p>
                <ol>
                  <li>Accumulation</li>
                  <li>Markup</li>
                  <li>Distribution</li>
                  <li>Panic Liquidation</li>
                </ol>

                <p>
                  <b>Chart Patterns</b>
                </p>
                <ol>
                  <li>Head & Shoulder</li>
                  <li>Inverted Head & Shoulder</li>
                  <li>Double Top / Bottom</li>
                  <li>Flag & Pennant</li>
                  <li>Symmetrical, Ascending, Descending Triangles</li>
                  <li>Wedge Patterns</li>
                  <li>Rounding Top / Bottom</li>
                  <li>Cup & Handle</li>
                  <li>Rectangles Bullish / Bearish</li>
                  <li>Triple Top / Bottom</li>
                </ol>
                <p className="para">
                  <b>Gaps & Gaps Analysis</b>
                </p>
                <p>Types of Gaps:</p>
                <ol>
                  <li>Common gap</li>
                  <li>Breakaway gap</li>
                  <li>Runaway gap</li>
                  <li>Exhaustion gap</li>
                  <li>Island cluster</li>
                </ol>
                <p className="para">
                  <b>
                    What does a technical indicator offer? Why use indicators?
                  </b>
                </p>
                <p class="para">Types of Indicators</p>
                <ol>
                  <li>Leading Indicator</li>
                  <li>Lagging Indicator</li>
                </ol>

                <p class="para">Moving Averages</p>
                <ol>
                  <li>Simple Moving Average</li>
                  <li>Exponential Moving Average</li>
                  <li>How to Trade on Moving Averages?</li>
                </ol>

                <p class="para">MACD</p>
                <ol>
                  <li>What is the MACD and How is it Calculated?</li>
                  <li>How to Trade on MACD?</li>
                </ol>

                <p class="para">RSI</p>
                <ol>
                  <li>What is Momentum?</li>
                  <li>Calculation of the RSI</li>
                  <li>Divergence</li>
                  <li>How to Trade on RSI?</li>
                </ol>

                <p class="para">On Balance Volume</p>
                <ol>
                  <li>Overview</li>
                  <li>Calculation of On Balance Volume</li>
                  <li>How to Trade on On Balance Volume</li>
                </ol>

                <p class="para">Stochastic</p>
                <ol>
                  <li>Overview</li>
                  <li>Construction</li>
                  <li>How to Trade on Stochastic</li>
                </ol>

                <p class="para">William %R</p>
                <ol>
                  <li>Overview</li>
                  <li>Signals</li>
                  <li>William %R</li>
                  <li>William Alligator</li>
                </ol>

                <p class="para">Bollinger Bands</p>
                <ol>
                  <li>Few Rules for Beginners</li>
                  <li>How to Trade on Bollinger Bands</li>
                  <li>How to Use Multiple Indicators</li>
                </ol>

                <p class="para">Money Flow Index</p>
                <ol>
                  <li>Overview</li>
                  <li>How to Trade on Money Flow Index</li>
                </ol>

                <p class="para">Trading Strategies (Intraday & Positional)</p>
                <ol>
                  <li>Price Action</li>
                  <li>Psychological Trading</li>
                  <li>Risk Management</li>
                </ol>

                <p class="para">The Dow Theory</p>
                <ol>
                  <li>Background</li>
                  <li>Principal Rule of the Dow Theory</li>
                </ol>

                <p class="para">Elliot Waves Theory</p>
                <ol>
                  <li>Elliot Wave Basics</li>
                  <li>How to Trade on Elliot Waves</li>
                </ol>

                <p class="para">Fibonacci Sequence</p>
                <ol>
                  <li>How to Trade on Fibonacci Retracement</li>
                  <li>How to Trade on Fibonacci Extension</li>
                </ol>
                <p className="para">
                  <b>
                    # Inclusive With Trading Psychology and how to manage the
                    risk
                  </b>
                </p>
                <p className="para" style={{ color: "gray" }}>
                  # GTS - Growth Trading Strategies- On Intraday & Positional
                  Delivery Trade Include Technical Analysis Trading Strategies.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingSeven">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSeven"
                aria-expanded="false"
                aria-controls="flush-collapseSeven"
              >
                Module 6: Option Trading Strategy (NCFM Certification)
              </p>
            </h2>
            <div
              id="flush-collapseSeven"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingSeven"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p class="para">Introduction to Options</p>
                <ol>
                  <li>Option Terminology</li>
                  <li>Options Payoffs</li>
                  <li>Payoff Profile of Buyer of Asset: Long Asset</li>
                  <li>Payoff Profile for the Seller of Asset: Short Asset</li>
                  <li>Payoff Profile for Buyers of Call Options: Long Call</li>
                  <li>
                    Payoff Profile for the Writer (Seller) of Call Options:
                    Short Call
                  </li>
                  <li>Payoff Profile for Buyers of Put Options: Long Put</li>
                  <li>
                    Payoff Profile for Writer (Seller) of Put Options: Short Put
                  </li>
                </ol>

                <p class="para">Advance Trading Strategies</p>
                <ol>
                  <li>Long Call</li>
                  <li>Short Call</li>
                  <li>Synthetic Long Call</li>
                  <li>Long Put</li>
                  <li>Short Put</li>
                  <li>Hedging</li>
                  <li>Covered Call</li>
                  <li>Long Combo</li>
                  <li>Protective Call</li>
                  <li>Covered Put</li>
                  <li>Long Straddle</li>
                  <li>Short Straddle</li>
                  <li>Long Strangle</li>
                  <li>Short Strangle</li>
                  <li>Collar</li>
                  <li>Bull Call Spread Strategy</li>
                  <li>Bull Put Spread Strategy</li>
                  <li>Bear Call Spread Strategy</li>
                  <li>Bear Put Spread Strategy</li>
                  <li>Long Call Butterfly</li>
                  <li>Short Call Butterfly</li>
                  <li>Long Call Condor</li>
                  <li>Short Call Condor</li>
                  <li>Option Chain Analysis</li>
                  <li>Greeks</li>
                  <li>Beta</li>
                  <li>Theta</li>
                  <li>Gamma</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEight">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEight"
                aria-expanded="false"
                aria-controls="flush-collapseEight"
              >
                Module 7: Fundamental Analysis(NCFM Certification)
              </p>
            </h2>
            <div
              id="flush-collapseEight"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingEight"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p class="para">1.) Introduction of Fundamental Analysis</p>
                <ol>
                  <li>What is Fundamental & Technical Analysis?</li>
                  <li>Difference Between Technical & Fundamental Analysis</li>
                  <li>Features & Benefits of Fundamental Analysis</li>
                </ol>

                <p class="para">
                  2.) Top-Down Approach in Fundamental Analysis
                </p>
                <ol>
                  <li>Economic Analysis</li>
                  <li>Industry Analysis</li>
                  <li>Company Analysis</li>
                </ol>

                <p class="para">3.) Economic Analysis</p>
                <p class="para">Global & Domestic Research (Events)</p>
                <ol>
                  <li>Political Events (Election Results)</li>
                  <li>Central Bank Meet (Central Bank Events)</li>
                  <li>Government Budget</li>
                  <li>Monsoon</li>
                  <li>OPEC Meeting</li>
                  <li>War or Terrorist Attack</li>
                </ol>

                <p class="para">Other Events</p>
                <ol>
                  <li>Rating Agencies</li>
                  <li>India VIX</li>
                  <li>Scandal</li>
                  <li>Insider Activity</li>
                  <li>Country Debt</li>
                </ol>

                <p class="para">Currency Analysis</p>

                <p class="para">Correlation of Events</p>

                <p class="para">Economic Data Analysis</p>
                <ol>
                  <li>Inventory</li>
                  <li>Retail Sales</li>
                  <li>Consumer Confidence</li>
                  <li>CPI – Consumer Price Index</li>
                  <li>PPI – Producer Price Index</li>
                  <li>Core Durable Goods Order</li>
                  <li>New Homes Sales</li>
                  <li>Building Permits</li>
                  <li>ADP Non-Farm Employment</li>
                  <li>Non-Farm Employment</li>
                  <li>Unemployment Claims</li>
                  <li>PMI</li>
                  <li>Industrial Production</li>
                  <li>GDP Gross Domestic Production</li>
                  <li>Trade Balance</li>
                  <li>Crude Oil Inventory</li>
                </ol>

                <p class="para">4.) Industry Analysis</p>

                <p class="para">Sector Analysis (Sector Selection)</p>
                <ol>
                  <li>Oil & Gas Sector</li>
                  <li>Aviation Sector</li>
                  <li>Paint Sector</li>
                  <li>IT Sector</li>
                  <li>Metal Sector</li>
                  <li>Pharma & FMCG Sector</li>
                  <li>Gems & Jewelry Sector</li>
                  <li>Banking Sector</li>
                  <li>Automobile Sector</li>
                  <li>Real Estate Sector</li>
                  <li>Cement Sector</li>
                  <li>Telecom Sector</li>
                  <li>Power Sector</li>
                </ol>

                <p class="para">5.) Script Selection</p>
                <p>Share or Commodity Selection</p>

                <p class="para">6.) Company Analysis (Valuation)</p>
                <ol>
                  <li>Cash Flow</li>
                  <li>EPS</li>
                  <li>P/E</li>
                  <li>Book Value</li>
                  <li>Price to Book Value</li>
                  <li>EBITDA</li>
                  <li>Profit Ratios</li>
                  <li>Dividend</li>
                  <li>Market Cap</li>
                  <li>Debt</li>
                  <li>Management</li>
                  <li>Promoters Holding</li>
                  <li>Volume</li>
                  <li>Open Interest</li>
                  <li>Beta</li>
                  <li>Moving Average</li>
                </ol>
                <p className="para">
                  <b>
                    # Inclusive With Mastering Fundamental Analysis Strategies
                    (MFA) -
                  </b>
                </p>
                <p className="para" style={{ color: "gray" }}>
                  This Strategy is based on Market & Data Analysis
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingNine">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseNine"
                aria-expanded="false"
                aria-controls="flush-collapseNine"
              >
                Module 8: Research Analyst Certification XV Series (NISM
                Certification)
              </p>
            </h2>
            <div
              id="flush-collapseNine"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingNine"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>Overview on NSE BSE MCX etc.</li>
                  <li>Understanding Income Statements</li>
                  <li>Balance Sheet Analysis</li>
                  <li>Cashflow Analysis</li>
                  <li>Brief on Various Software</li>
                  <li>How Do Capital Markets Work Worldwide</li>
                  <li>Fundamental vs Technical Analysis</li>
                  <li>Top-down and Bottom-up Approach</li>
                  <li>Macro & Micro Aspects in Relation to Stock Markets</li>
                  <li>
                    Markets Affected by IIP, Inflation, PMI, Monetary Policy
                    etc.
                  </li>
                  <li>Regression Analysis & ANOVA Distribution</li>
                  <li>Correlation & Covariance Analysis</li>
                  <li>
                    Financial Terminology such as Buyback, Bonus, Corporate
                    Actions etc.
                  </li>
                  <li>Quarterly Results Analysis</li>
                  <li>Annual Reports Analysis</li>
                  <li>Management Discussion</li>
                  <li>Directors Reports</li>
                  <li>How to Read Company Annual Report & DRHP</li>
                  <li>NPV & IRR Rules</li>
                  <li>HPR & HPY</li>
                  <li>Statistical Concepts & Market Returns</li>
                  <li>Demand Supply & Elasticity Concepts</li>
                  <li>Comparative Analysis</li>
                  <li>IPO Analysis</li>
                  <li>Stock Portfolio Model</li>
                  <li>Descriptive Statistics Models</li>
                  <li>Understanding Standard Deviation & Variance</li>
                  <li>Understanding CAL, SML & CML Equations</li>
                  <li>Markowitz Modern Portfolio Model</li>
                  <li>Skewness, Kurtosis & Range</li>
                  <li>Net Asset Value Analysis</li>
                  <li>Canslim Model by William J. O'Neil</li>
                  <li>Relative & Comps Techniques</li>
                  <li>Dividend Discount Model</li>
                  <li>
                    Ratio Analysis e.g. ICR, Debt Equity, PAT Margin, Debtors
                    Equity etc.
                  </li>
                  <li>Step Wise DuPont Analysis</li>
                  <li>Sharpe & Treynor Ratio, Alpha & Beta Analysis</li>
                  <li>PE, EPS, ROI, ROA Analysis</li>
                  <li>Capital Budgeting & Cost of Capital</li>
                  <li>Capital Asset Pricing Model</li>
                  <li>
                    Weak Form, Semi-Strong Form, and Strong Form Market
                    Efficiency
                  </li>
                  <li>Company Analysis – Qualitative Dimensions</li>
                  <li>Company Analysis – Quantitative Dimensions</li>
                  <li>Banking Sector Terminology</li>
                  <li>Mutual Fund Analysis</li>
                  <li>Basic Use of Excel & Techniques</li>
                  <li>Qualities of a Good Research Report</li>
                  <li>Time Value of Money</li>
                  <li>Forecasting Techniques</li>
                  <li>Revenue Builders</li>
                  <li>Building the Asset and Depreciation Schedule</li>
                  <li>Building P&L & Balance Sheet</li>
                  <li>Building Assumptions & Debt Schedule</li>
                  <li>Understanding FCFF, FCFI</li>
                  <li>Building Capex Schedule</li>
                  <li>Decoding Ke, Kd & Kp with WACC</li>
                  <li>CAPM and Its Understanding</li>
                  <li>EV/EBIDTA & SOTP Understanding Concepts</li>
                  <li>Understanding Calculators</li>
                  <li>Basics of Derivatives</li>
                  <li>Forwards & Futures</li>
                  <li>Put-Call Parity</li>
                  <li>Basic Hedging Strategies for Analysts</li>
                  <li>Live & Desk Cases on Companies</li>
                  <li>Knowledge Sessions on Undervalued & Overvalued Stocks</li>
                  <li>Understanding Analyst Presentations</li>
                  <li>Review on Current Macro & Micro Trends</li>
                  <li>
                    Review on Global Trends like Eurozone Crisis, Oil Crisis,
                    and IT Visa Issues etc.
                  </li>
                  <li>
                    Sessions Will Include a Mid-Term and an End-Term Examination
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTen">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTen"
                aria-expanded="false"
                aria-controls="flush-collapseTen"
              >
                Module 9: Investment Analyst & Portfolio Management (NCFM
                Certification)
              </p>
            </h2>
            <div
              id="flush-collapseTen"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTen"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ul>
                  <li>
                    Objectives of Investment Decisions (Introduction, Types of
                    Investors, Constraints, Goals of Investors)
                  </li>
                  <li>
                    Financial Markets (Introduction, Primary & Secondary
                    Markets, Trading in Secondary Markets, The Money Market,
                    Repos and Reverses, The Bond Market, Common Stocks, etc)
                  </li>
                  <li>
                    Fixed Income Securities (Introduction: The time value of
                    Money, Simple and Compound Interest Rates, Real and Nominal
                    Interest Rates, Bond Pricing Fundamentals, Bond Yields,
                    Interest Rates, Macaulay Duration and Modified Duration,
                    etc)
                  </li>
                  <li>
                    Capital Market Efficiency (Introduction, Market Efficiency,
                    Departures from the EMH)
                  </li>
                  <li>
                    Financial Analysis and Valuation (Introduction, The Analysis
                    of Financial Statement, Financial Ratios, The Valuation of
                    common Stocks, Technical Analysis)
                  </li>
                  <li>
                    Modern Portfolio Theory (Introduction, Diversification and
                    Portfolio Risks, Equilibrium Module: The Capital Asset
                    Pricing Module, etc)
                  </li>
                  <li>
                    Valuation of Derivatives (Introduction, Forwards and
                    Futures, Call and Put Options, Forward and Future Pricing,
                    Option Pricing, Black-Scholes Formula, etc)
                  </li>
                  <li>
                    Investment Management (Introduction, Investment Companies,
                    Active vs. Passive Portfolio Management, Costs of
                    Management: Entry/Exit Loads and Fees, Net Asset Value,
                    Classification of Funds, Other Investment Companies,
                    Performance Assessment of managed funds, etc)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEleven">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEleven"
                aria-expanded="false"
                aria-controls="flush-collapseEleven"
              >
                Module 10: Security Operation and Risk Management (NISM
                Certification)
              </p>
            </h2>
            <div
              id="flush-collapseEleven"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingEleven"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>
                    Introduction to Risk Management (what is Risk & Type of
                    Risk)
                  </li>
                  <li>Understanding of Margin mechanism (Stock and index)</li>
                  <li>Understanding of VAR & SPAN margin mechanism</li>
                  <li>Live surveillance training</li>
                  <li>Internal & External policy regarding Risk management</li>
                  <li>Brief understanding of Banking, DP, DEMAT, KYC</li>
                  <li>Compliance Management</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwelve">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwelve"
                aria-expanded="false"
                aria-controls="flush-collapseTwelve"
              >
                Module 11: Mutual Funds VA Certification
              </p>
            </h2>
            <div
              id="flush-collapseTwelve"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwelve"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>MBA & BBA/CA/CS/CPT Students</li>
                  <li>Anyone who wants the job</li>
                  <li>10+2, BA. B.com. B.Sc. Pursuing Students</li>
                  <li>
                    Those who want to pursue the career in financial services
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvanceDiplomaFinancial;

import React from "react";
import stock from "../../../images/stock10.png";
import ai from "../../../images/ai12.jpg";

const NCFMCapitalMarketModule = () => {
  return (
    <div className="container">
      <div className="container mt-5 mb-5">
        <p className="allheading">Capital Market Module - NCFM CERTIFICATION</p>
        <div className="row">
          <div className="col-md-6">
            <img width={'100%'} height={'100%'} src={ai} alt="" />
          </div>
          <div className="col-md-6">
        <p className="para" style={{ color: "gray" }}>
          NCFM Capital Market (NSE) Dealer Module Certification & Exam
          Preparation by TSE Leading Stock Market Institute For Financial Market
          Courses in Delhi. Capital market dealer module, a one-month program
          for aspirants wish to master capital market operations. This module is
          starting level of stock market courses for job. The program offers
          powerful insight into various tools and techniques need to understand
          capital market size, structure, and functions. Accelerate your career
          as a capital market dealer to get a job in National Stock Exchange
          (NSE).
        </p>

          </div>
          <div className="col-md-6 mt-5">
          <p className="para" style={{ color: "gray" }}>
          NCFM Capital market module is one of the most important programs of
          the National Stock Exchange (NSE). It is a must-do module to get a job
          in Stock Broking Company. Aspirants planning to start their own stock
          broking business can also enrol for this short-term course. Besides,
          who wants to add value to resume when applying for jobs in the
          financial sector/ IT/ BPO.
        </p>
        <p className="para">
          <b>What Student Will Get?</b>
        </p>
        <p className="para" style={{ color: "gray" }}>
          Upon completion of the Capital Market Module, students will receive a
          certificate from TSEand from NCFM. The certificate will testify your
          skills as an expert in the capital market. You will also receive the
          following:
        </p>
        <p className="para">
          • NSE Capital Market Certification • NCFM Capital Market Certification
        </p>
        <p className="para">
          # NCFM Certificates are subjected to appearance for the exam at the
          NSE Center. You will get 100% preparation at the branch.
        </p>
          </div>
          <div className="col-md-6 mt-5">
          <img width={'100%'} src={stock} alt="" />
          </div>
        </div>
        
        <p className="heading">DETAILED CURRICULUM</p>

        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Module 1: Capital Market & its Operation (NCFM Certification)
              </p>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>
                    Basic knowledge of capital market (Primary Market &
                    Secondary Market)
                  </li>
                  <li>Major Market Participants</li>
                  <li>Major exchanges and indices</li>
                  <li>Legal Framework and regulations</li>
                  <li>Trading and Trading Membership</li>
                  <li>Clearing and Settlement Process</li>
                  <li>Fundamental Valuation concepts</li>
                  <li>Glimpse of various trading software (ODIN, NEAT, NOW)</li>
                  <li>Practical training of market operations</li>
                  <li>
                    Practical Classes daily from 10.00 am – 03.30 pm (live
                    trading and methodology)
                  </li>
                  <li>
                    Online Mock test (500 Question & answer test series) of NCFM
                    Capital market module
                  </li>
                  <p className="para">
                    <b>Who Can Take Capital Market Module?</b>
                  </p>
                  <p className="para" style={{ color: "gray" }}>
                    Aspirants who complete the capital market course will
                    develop a skill that helps you land your dream job in the
                    primary and secondary market. Jobs that are ideal for
                    capital market professionals include:
                  </p>
                </ol>
                <ol>
                  <li>
                    Capital market beginners can take the course to build a
                    lucrative career in financial services.
                  </li>
                  <li>
                    Teachers, investors, and anybody interested in working in
                    Indian stock market operations can join the capital market
                    module to enhance learning.
                  </li>
                  <li>
                    Stock brokers, sub-brokers, and stock analysts looking to
                    open new career opportunities can take the course.
                  </li>
                  <li>
                    BPO / ITES professionals who want to design and implement
                    mathematical models for fundamental valuation of securities.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NCFMCapitalMarketModule;

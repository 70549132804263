import React from "react";
import stock from "../../../images/stock12.png";

const NISMSeriesXXVResearch = () => {
  return (
    <div className="container">
      <div className="container mt-5 mb-5">
        <p className="allheading">NISM Research Analyst</p>
        <div className="row">
          <div className="col-md-6">
            <img src={stock} width={'100%'} alt="stock" />
          </div>
          <div className="col-md-6">
        <p className="para" style={{ color: "gray" }}>
          About <b> “TSE Research Analyst </b> is unique program designed for
          all those who wish to become a research analyst, who wishes to give
          calls /tips through SMS, website, radio, T.V. or any other public
          media or wish to provide call to their clients. As per SEBI regulation
          2014, NISM series 15 research analyst certification is must and aims
          to entrance qualify of service provides by the research analyst in the
          financial service industry.
        </p>
        <p className="para" style={{ color: "gray" }}>
          Diploma in research analyst is a job oriented program. This program is
          a comprehensive program aimed to correlate and combine knowledge
          of Technical analysis/ Fundamental analysis and research analysis. It
          is now mandatory for research departments in broking house, research
          house, and other financial institutions to have certified research
          analyst who has passed SEBI NISM SERIES XV certification.{" "}
        </p>

          </div>
          <div className="col-md-6 mt-5">
          <p className="para">
          Technical analysis has become very much popular in the past decade and
          one can predict the moves of the market viewing the historical data.
          Various charts, indicators, graph help to predict the movement of the
          market and price levels is predicted. Thus one learns patterns,
          trends, and moves of the market in technical analysis, fundamental
          analysis are a method to evaluate the stock/security by attempting to
          measure the intrinsic value of the stock. It is based on event, news,
          data analysis.
        </p>
        <p className="para">
          Technical analysis has become very much popular in the past decade and
          one can predict the moves of the market viewing the historical data.
          Various charts, indicators, graph help to predict the movement of the
          market and price levels is predicted. Thus one learns patterns,
          trends, and moves of the market in technical analysis, fundamental
          analysis are a method to evaluate the stock/security by attempting to
          measure the intrinsic value of the stock. It is based on event, news,
          data analysis.
        </p>
          </div>
          <div className="col-md-6 mt-5">
                        <img src={stock} width={'100%'} alt="stock" />

          </div>
        </div>
       
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Unique Feature of Diploma in Research Analysis
              </p>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>
                    Diploma in Research Analysis is a higher program that
                    includes technical analysis, fundamental analysis, and
                    research analysis.
                  </li>
                  <li>With this program, one can upgrade in the industry.</li>
                  <li>A complete blend of theory + practical’s</li>
                  <li>Learn to generate calls/tips.</li>
                  <li>Learn to identify the upcoming stocks.</li>
                  <li>
                    Learn to know the intrinsic values of stock and predict
                    trend for better profitability prospect.
                  </li>
                  <li>Learn to forecast prices and moves.</li>
                  <li>Become 100% confident to trade and earn.</li>
                  <li>Faculty with more than 15 years experience.</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                What Will You Get?
              </p>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>NCFM Technical Analysis Certification</li>
                  <li>NCFM Fundamental Analysis</li>
                  <li>NISM Research analysis XV Series certificate</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Job Opportunities
              </p>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p className="para">
                  A research analyst can work in different domain like
                  marketing, business operations, finance, management, and
                  public policy. You can apply different job titles, for
                  instance, market research analyst, operations research
                  analyst, equity research analyst, financial analyst, and
                  economic research analyst.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Certifications
              </p>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>1 Certificate of NISM Module</li>
                  <li>2 Certificate of NCFM Module</li>
                  <li>Diploma in Research Analysis From TSE</li>
                </ol>
                <p className="para" style={{ color: "gray" }}>
                  *NCFM and NISM Certificates are subjected to appearance for
                  the exam at the NSE Center. You get 100% preparation at the
                  branch
                </p>
              </div>
            </div>
          </div>
          <p className="heading mb-1 mt-5">
            <b>Detail Curriculum</b>
          </p>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                Module1: Technical Analysis & Its Application (NCFM
                Certification)
              </p>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p className="para">
                  <b>Introduction to Technical Analysis</b>
                </p>
                <p className="para">
                  What is technical analysis? The basis of technical analysis
                  Difference between technical vs fundamental analysis
                </p>
                <p className="para">
                  <b>Type of Charts</b>
                </p>
                <p className="para" style={{ color: "gray" }}>
                  Introduction to chart
                </p>
                <p className="para" style={{ color: "gray" }}>
                  The various types of price charts
                </p>
                <ol>
                  <li>Line chart</li>
                  <li>Bar chart</li>
                  <li>Candlestick chart</li>
                  <li>Kagi chart</li>
                  <li>Point & Figure chart</li>
                  <li>Renko chart</li>
                  <li>Three Line Break chart</li>
                </ol>
                <p className="para">
                  <b>Trend lines</b>
                </p>
                <ol>
                  <li>What is the purpose of drawing trend lines?</li>
                  <li>How to plot trend lines</li>
                </ol>
                <p className="para" style={{ color: "red" }}>
                  Candlestick study
                </p>
                <p className="para">
                  <b>One candlestick pattern</b>
                </p>
                <ol>
                  <li>Doji</li>
                  <li>Hammer / Hanging Man</li>
                  <li>Inverted Hammer / Shooting Star</li>
                  <li>Spinning Top</li>
                  <li>Marubozu</li>
                </ol>
                <p className="para">
                  <b>Double candlestick pattern</b>
                </p>
                <ol>
                  <li>Bullish /Bearish Engulf</li>
                  <li>Bullish /Bearish Harami</li>
                  <li>Piercing pattern /Dark cloud cover</li>
                  <li>Tweezer Top & Bottom</li>
                </ol>

                <p className="para">Triple candlestick pattern</p>
                <ol>
                  <li>Morning star /Evening star</li>
                  <li>Three white shoulders / Three black crows</li>
                  <li>Abandoned body (Bullish & Bearish)</li>
                  <li>Tasuki Gap (Bullish & Bearish)</li>
                </ol>
                <p className="para">
                  <b>Five candlestick pattern</b>
                </p>
                <ol>
                  <li>Rising three methods & falling three methods</li>
                </ol>
                <p className="para">
                  <b>Support & Resistance</b>
                </p>
                <p className="para" style={{ color: "gray" }}>
                  What is Support? What is Resistance? Change of support to
                  resistance and vice versa
                </p>
                <p className="para">
                  <b>Charts patterns and their study</b>
                </p>
                <p className="para">Four stages:</p>
                <ol>
                  <li>Accumulation</li>
                  <li>Markup</li>
                  <li>Distribution</li>
                  <li>Panic liquidation</li>
                </ol>
                <p className="para">
                  <b>Chart patterns:</b>
                </p>
                <ol>
                  <li>Head & Shoulder</li>
                  <li>Inverted Head & Shoulder</li>
                  <li>Double top/bottom</li>
                  <li>Flag & Pennant</li>
                  <li>Symmetrical, Ascending, Descending Triangles</li>
                  <li>Wedge Patterns</li>
                  <li>Rounding top/bottom</li>
                  <li>Cup & Handle</li>
                  <li>Rectangles Bullish / Bearish</li>
                  <li>Triple top/bottom</li>
                </ol>
                <p className="para">
                  <b>Gaps & Gaps Analysis Types of Gaps:</b>
                </p>
                <ol>
                  <li>Common gap</li>
                  <li>Breakaway gap</li>
                  <li>Runaway gap</li>
                  <li>Exhaustion gap</li>
                  <li>Island cluster</li>
                </ol>
                <p className="para">
                  <b>Oscillators & indicators</b>
                </p>
                <p className="para">
                  What does a technical indicator offer? Why use indicators?
                </p>
                <ol>
                  <li>
                    Types of indicators:
                    <ul>
                      <li>Leading indicator</li>
                      <li>Lagging indicator</li>
                    </ul>
                  </li>
                  <li>
                    Moving Averages
                    <ul>
                      <li>Simple moving average</li>
                      <li>Exponential moving average</li>
                      <li>How to trade on moving averages?</li>
                    </ul>
                  </li>
                  <li>
                    MACD
                    <ul>
                      <li>What is the MACD and how is it calculated?</li>
                      <li>How to trade on MACD?</li>
                    </ul>
                  </li>
                  <li>
                    RSI
                    <ul>
                      <li>What is momentum?</li>
                      <li>Calculation of the RSI</li>
                      <li>Divergence</li>
                      <li>How to trade on RSI?</li>
                    </ul>
                  </li>
                  <li>
                    On Balance Volume
                    <ul>
                      <li>Overview</li>
                      <li>Calculation of On Balance Volume</li>
                      <li>How to trade on On Balance Volume</li>
                    </ul>
                  </li>
                  <li>
                    Stochastic
                    <ul>
                      <li>Overview</li>
                      <li>Construction</li>
                      <li>How to trade on On stochastic</li>
                    </ul>
                  </li>
                  <li>
                    William %R
                    <ul>
                      <li>Overview</li>
                      <li>Signals</li>
                      <li>How to trade on William %R</li>
                    </ul>
                  </li>
                  <li>
                    Bollinger Bands
                    <ul>
                      <li>Few rules for beginners</li>
                      <li>How to trade on bollinger bands</li>
                      <li>How to use multiple indicator</li>
                    </ul>
                  </li>
                  <li>
                    Money Flow Index
                    <ul>
                      <li>Overview</li>
                      <li>How to trade on Money Flow Index</li>
                    </ul>
                  </li>
                  <li>Trading strategy</li>
                  <li>
                    The Dow Theory
                    <ul>
                      <li>Background</li>
                      <li>Principal rule of the Dow Theory</li>
                    </ul>
                  </li>
                  <li>
                    Elliot Waves Theory
                    <ul>
                      <li>Elliot wave basics</li>
                      <li>How to trade on Elliot waves</li>
                    </ul>
                  </li>
                  <li>
                    Fibonacci sequence
                    <ul>
                      <li>How to trade on Fibonacci retracement</li>
                      <li>How to trade on Fibonacci extension</li>
                    </ul>
                  </li>
                </ol>
                <p className="para">
                  <b>
                    # Inclusive With Trading Psychology and how to manage the
                    risk
                  </b>
                </p>
                <p className="para">
                  # GTS - Growth Trading Strategies- On Intraday & Positional
                  Trade Include 9 Technical Analysis Trading Strategies.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                Module 2: Fundamental Analysis (NCFM Certification)
              </p>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>
                    Introduction of Fundamental Analysis
                    <ul>
                      <li>What is Fundamental & Technical Analysis?</li>
                      <li>
                        Difference between technical & fundamental analysis
                      </li>
                      <li>Features & benefits of Fundamental analysis</li>
                    </ul>
                  </li>
                  <li>
                    Top-Down Approach in Fundamental Analysis
                    <ul>
                      <li>Economic Analysis</li>
                      <li>Industry Analysis</li>
                      <li>Company analysis</li>
                    </ul>
                  </li>
                  <li>
                    Economic Analysis
                    <ul>
                      <li>
                        Global & Domestic Research (Events)
                        <ul>
                          <li>Political events (Election Results)</li>
                          <li>Central Bank Meet (Central Bank events)</li>
                          <li>Government Budget</li>
                          <li>Monsoon</li>
                          <li>OPEC Meeting</li>
                          <li>War or Terrorist Attack</li>
                        </ul>
                      </li>
                      <li>
                        Other Events
                        <ul>
                          <li>Rating agencies</li>
                          <li>India VIX</li>
                          <li>Scandal</li>
                          <li>Insider Activity</li>
                          <li>Country Debt</li>
                        </ul>
                      </li>
                      <li>Currency Analysis</li>
                      <li>Correlation Of Events</li>
                      <li>
                        Economic Data Analysis
                        <ul>
                          <li>Inventory</li>
                          <li>Retail Sales</li>
                          <li>Consumer Confidence</li>
                          <li>CPI – Consumer Price Index</li>
                          <li>PPI – Producer Price Index</li>
                          <li>Core Durable Goods Order</li>
                          <li>New Homes Sales</li>
                          <li>Building Permits</li>
                          <li>ADP Non Farm employment</li>
                          <li>Non Farm Employment</li>
                          <li>Unemployment Claims</li>
                          <li>PMI</li>
                          <li>Industrial Production</li>
                          <li>GDP Gross Domestic Production</li>
                          <li>Trade Balance</li>
                          <li>Crude Oil Inventory</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Industry Analysis
                    <ul>
                      <li>
                        Sector Analysis (Sector Selection)
                        <ul>
                          <li>Oil & Gas Sector</li>
                          <li>Aviation Sector</li>
                          <li>Paint Sector</li>
                          <li>T. Sector</li>
                          <li>Metal Sector</li>
                          <li>Pharma & FMCG Sector</li>
                          <li>Jems & Jewellery Sector</li>
                          <li>Banking Sector</li>
                          <li>Automobile Sector</li>
                          <li>Real Estate Sector</li>
                          <li>Cement Sector</li>
                          <li>Telecom Sector</li>
                          <li>Power Sector</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Script Selection
                    <ul>
                      <li>Share or Commodity Selection</li>
                    </ul>
                  </li>
                  <li>
                    Company Analysis (Valuation)
                    <ul>
                      <li>Cash Flow</li>
                      <li>EPS</li>
                      <li>P/E</li>
                      <li>Book Value</li>
                      <li>Price to Book Value</li>
                      <li>EBITDA</li>
                      <li>Profit Ratios</li>
                      <li>Dividend</li>
                      <li>Market Cap</li>
                      <li>DEBT</li>
                      <li>Management</li>
                      <li>Promoters Holding</li>
                      <li>Volume</li>
                      <li>Open Interest</li>
                      <li>Beta</li>
                      <li>Moving Average</li>
                    </ul>
                  </li>
                </ol>
                <p className="para">
                  <b>
                    # Inclusive With Mastering Fundamental Analysis Strategies
                    (MFA) -
                  </b>
                </p>
                <p className="para">
                  This Strategy is based on Market & Data Analysis.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFive">
              <p
                class="accordion-button collapsed accordionheading"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                Module 3. - Research Analyst Certification (NISM Certification)
              </p>
            </h2>
            <div
              id="flush-collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>Overview on NSE BSE MCX etc.</li>
                  <li>Understanding Income statements</li>
                  <li>Balance sheet Analysis</li>
                  <li>Cashflow Analysis</li>
                  <li>Brief on various software</li>
                  <li>How do capital Markets work worldwide</li>
                  <li>Fundamental vs Technical Analysis</li>
                  <li>Top-down and Bottom-up Approach</li>
                  <li>Macro & Micro Aspects in relation to stock markets</li>
                  <li>
                    Markets affect by IIP, Inflation, PMI, Monetary Policy etc.
                  </li>
                  <li>Regression Analysis &Anova distribution</li>
                  <li>Correlation & Covariance Analysis</li>
                  <li>
                    Financial terminology such as Buyback, Bonus, Corporate
                    Actions etc.
                  </li>
                  <li>Quarterly Results Analysis</li>
                  <li>Annual Reports Analysis</li>
                  <li>Management Discussion</li>
                  <li>Directors Reports</li>
                  <li>How to read Company Annual Report & DRHP</li>
                  <li>NPV & IRR Rules</li>
                  <li>HPR & HPY</li>
                  <li>Statistical concepts & Market Returns</li>
                  <li>Demand Supply & Elasticity Concepts</li>
                  <li>Comparative Analysis.</li>
                  <li>IPO Analysis</li>
                  <li>Stock Portfolio model.</li>
                  <li>Descriptive Statistics Models.</li>
                  <li>Understanding Standard Deviation & Variance.</li>
                  <li>Understanding CAL, SML & CML equations.</li>
                  <li>Markowitz Modern portfolio model.</li>
                  <li>Skewness, Kurtosis & Range.</li>
                  <li>Net Asset Value Analysis.</li>
                  <li>Canslim Model by William J. ONeil</li>
                  <li>Relative & Comps Techniques.</li>
                  <li>Dividend Discount Model.</li>
                  <li>
                    Ratio Analysis e.g.ICR, Debt Equity, Pat margin, Debtors
                    equity etc.
                  </li>
                  <li>Step Wise Dupoint Analysis</li>
                  <li>Sharpe &Treynor Ratio, Alpha & Beta Analysis</li>
                  <li>PE, EPS, ROI, ROA Analysis</li>
                  <li>Capital Budgeting & Cost of Capital</li>
                  <li>Capital Asset Pricing Model</li>
                  <li>
                    Weak form, semi-strong form, and strong form market
                    efficiency
                  </li>
                  <li>Company Analysis – Qualitative Dimensions</li>
                  <li>Company Analysis – Quantitative Dimensions</li>
                  <li>Banking Sector Terminology</li>
                  <li>Mutual Fund Analysis.</li>
                  <li>Basic Use of Excel & Techniques.</li>
                  <li>Qualities of a good Research Report</li>
                  <li>Time value of Money</li>
                  <li>Forecasting Techniques.</li>
                  <li>Revenue Builders.</li>
                  <li>Building the asset and depreciation schedule.</li>
                  <li>Building P&L & Balance sheet.</li>
                  <li>Building Assumptions & Debt Schedule.</li>
                  <li>Understanding FCFF,FCFI.</li>
                  <li>Building Capex Schedule.</li>
                  <li>Decoding Ke, Kd&Kp with WACC.</li>
                  <li>CAPM and its understanding.</li>
                  <li>EV/EBIDTA & SOTP Understanding Concepts</li>
                  <li>Understanding Calculators</li>
                  <li>Basics Of Derivatives</li>
                  <li>Forwards & Futures</li>
                  <li>Put-Call Parity</li>
                  <li>Basic Hedging strategies for Analysts</li>
                  <li>Live & Desk Cases on companies</li>
                  <li>Knowledge sessions on undervalued & overvalued stocks</li>
                  <li>Understanding Analyst Presentations</li>
                  <li>Review on current Macro & Microtrends.</li>
                  <li>
                    Review on Global trends like Eurozone crisis, Oil crisis,
                    and IT visa issues etc.
                  </li>
                  <li>
                    Sessions will be including a mid-term and an end term
                    examination.
                  </li>
                </ol>
                <p className="para">
                  <b>Who Should Do This Course?</b>
                </p>
                <ol>
                  <li>MBA & BBA/CA/CS/CPT Students</li>
                  <li>
                    Along with graduation a 100% skill oriented and job oriented
                    program
                  </li>
                  <li>Anyone who wants a job</li>
                  <li>10+2, BA, B.com, B.Sc. Pursuing Students</li>
                  <li>
                    Those who want to pursue the career in financial industry,
                    broking industry, banks
                  </li>
                  <li>Traders investors</li>
                  <li>Professionals who need upgradation</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NISMSeriesXXVResearch;
